<template>
    <div>
        <BaseInfo :baseInfo="$store.state.user.baseInfo" />
        <NavBar
                title="健康宣教"
                left-arrow
                @click-left="$router.go(-1)"
        />
        <Collapse v-model="activeName" accordion>
            <CollapseItem title="科普图文" name="-1">
                <div v-if="size_kp >=0">
                    <vrow v-for="index in size_kp" :key="index" gutter="20" type="flex" justify="center" style="margin-top: 15px">
                        <vcol span="12">
                            <div align="center">
                                <div v-if="(index-1)*2 <total_kp">
                                    <vimg
                                            :src="picUrl+kptws[(index-1)*2].cover"
                                            @click="showExe(kptws[(index-1)*2].send_id)"
                                    />
                                    <div class="text">{{kptws[(index-1)*2].send_title}}</div>
                                </div>
                            </div>
                        </vcol>
                        <vcol span="12">
                            <div align="center">
                                <div v-if="(index-1)*2+1 <total_kp">
                                    <div class="text">{{kptws[(index-1)*2+1].note}}</div>
                                    <vimg
                                            :src="picUrl+kptws[(index-1)*2].cover"
                                            @click="showExe(kptws[(index-1)*2+1].send_id)"
                                    />
                                    <div class="text">{{kptws[(index-1)*2+1].send_title}}</div>
                                </div>
                            </div>
                        </vcol>
                    </vrow>
                </div>
            </CollapseItem>
            <CollapseItem title="通用视频" name="0">
                <div v-if="size_ty >=0">
                    <vrow v-for="index in size_ty" :key="index" gutter="20" type="flex" justify="center" style="margin-top: 15px">
                        <vcol span="12">
                            <div align="center">
                                <div v-if="(index-1)*2 <total_ty">
                                    <vimg
                                            :src="picUrl+tysps[(index-1)*2].cover"
                                            @click="showExe(tysps[(index-1)*2].send_id)"
                                    />
                                    <div class="text">{{tysps[(index-1)*2].send_title}}</div>
                                </div>
                            </div>
                        </vcol>
                        <vcol span="12">
                            <div align="center">
                                <div v-if="(index-1)*2+1 <total_ty">
                                    <div class="text">{{tysps[(index-1)*2+1].note}}</div>
                                    <vimg
                                            :src="picUrl+tysps[(index-1)*2].cover"
                                            @click="showExe(tysps[(index-1)*2+1].send_id)"
                                    />
                                    <div class="text">{{tysps[(index-1)*2+1].send_title}}</div>
                                </div>
                            </div>
                        </vcol>
                    </vrow>
                </div>
            </CollapseItem>
            <div v-for="(val,key) in bwspHash" :key="key">

                <CollapseItem :title="key" :name="key">
                    <div v-if="val.length >=0">
                        <vrow v-for="index in val.length" :key="index" gutter="20" type="flex" justify="center" style="margin-top: 15px">
                            <vcol span="12">
                                <div align="center">
                                    <div v-if="(index-1)*2 <val.length">
                                        <vimg
                                                :src="picUrl+val[(index-1)*2].cover"
                                                @click="showExe(val[(index-1)*2].send_id)"
                                        />
                                        <div class="text">{{val[(index-1)*2].send_title}}</div>
                                    </div>
                                </div>
                            </vcol>
                            <vcol span="12">
                                <div align="center">
                                    <div v-if="(index-1)*2+1 <val.length">
                                        <div class="text">{{val[(index-1)*2+1].note}}</div>
                                        <vimg
                                                :src="picUrl+val[(index-1)*2].cover"
                                                @click="showExe(val[(index-1)*2+1].send_id)"
                                        />
                                        <div class="text">{{val[(index-1)*2+1].send_title}}</div>
                                    </div>
                                </div>
                            </vcol>
                        </vrow>
                    </div>
                </CollapseItem>
            </div>
        </Collapse>


        <empty v-if="nodata" description="查无数据" />

        <RLFooter/>
    </div>
</template>

<script>

    import {Empty,Image as vimg,Col as vcol, Row as vrow,Collapse, CollapseItem} from 'vant';
    import {getFollows} from '@/api/follow';
    import {getExerciseList} from '@/api/jkgl';
    import {setToken,removeToken} from '@/utils/auth';
    import {getUser} from '@/api/user';

    export default {
        name: 'jkgl',
        components: {
            Empty,vimg,vcol,vrow,Collapse, CollapseItem
        },
        data(){
            return {
                user:{
                    name:'',
                    idCard:'',
                    telephone:'',
                    gender:'1',
                    birthday:'',
                    address:'',
                    detail_address:'',
                    id:''
                },
                kptws:[],
                tysps:[],
                bwspHash:{},
                total_kp:0,
                size_kp:0,
                total_ty:0,
                size_ty:0,
                total_bw:0,
                size_bw:0,
                nodata:false,
                types:[],
                picUrl:'https://guanjietong.rilintech.com/',
                activeName:'-1',
                activeNameBw:'1'
            };
        },
        computed:{

        },
        methods:{
            initidx(){
                console.info(this.$store.state.user);
                //this.$store.commit('updatePid',this.$route.params.pid);
                let query = this.$route.query;

                let follow_time=query.follow_time?query.follow_time : this.parseTime(new Date(),'{y}-{m}-{d}');
                getFollows({follow_time:follow_time,pid:this.$route.params.pid}).then((response) => {
                    if(response.code==200 ){
                        // this.loading=true;
                        this.active=response.data.total;

                        this.followcrfs=response.data.rows;
                        console.log(response.data)

                        if(this.followcrfs.length==0){
                            this.nodata = true
                        }
                    }
                });
                // getqueidxs({pid:this.$route.params.pid}).then((response) => {
                //     this.loading=true;
                //     if(this.followcrfs.length==0){
                //         this.followcrfs=response.data;
                //     }
                // });
            },
            showExe(send_id){
                this.$router.push({path: '/exe/'+send_id});
            },
            formaterState(fins){
                if(fins==1){
                    return '未完成';
                }else{
                    return '已完成';
                }
            },
            formaterTime(ctime,uptime){
                let res="";
                if(ctime){
                    res+="创建时间："+this.parseTime(ctime);
                }
                if(uptime){
                    res+="    更新时间："+this.parseTime(uptime);
                }
                return res;
            },
            initData(){
                let query = this.$route.query;
                this.follow_time=query.follow_time;
                // if(this.follow_time == undefined){
                //     this.follow_time = this.parseTime(new Date(),'{y}-{m}-{d}');
                // }
                getExerciseList({type:'exe',date:this.follow_time}).then((response) => {
                    console.log(response)
                    if(response.code==200 ){
                        this.kptws = response.data.kptw_sends;
                        this.tysps = response.data.tybw_sends;
                        this.bwspHash = response.data.bwHash;

                        this.total_kp = response.data.kptw_sends.length
                        this.size_kp = Math.ceil(this.total_kp/2)

                        this.total_ty = response.data.tybw_sends.length
                        this.size_ty = Math.ceil(this.total_ty/2)
                    }

                });
            },
        }
        ,
        created() {
          // 开发环境
          if(process.env.NODE_ENV=='development'){
            getUser().then((res) => {
              console.info(res.data);

              if(res.data){
                this.$store.commit('updateOpenid',res.data.openid);
                this.$store.commit('updatePid',res.data.id);
                this.$store.commit('updateBaseInfo',res.data);
                if(res.data.name !=''){
                  this.substatus = true
                }
                this.user.id=res.data.id;
                this.user.name=res.data.name;
                this.user.birthday=res.data.birthday;
                // this.user.idCard=response.data.idCard;
                this.user.telephone=res.data.telephone;
                this.user.gender=res.data.gender+'';
                this.user.address=res.data.address;
                this.user.detail_address=res.data.detail_address;
                // this.initBaseInfo();

                this.initData()

              }
            });
          }else{
            //生产环境
            var query = this.$route.query;
            if(query.code || this.getQueryString('code')){
              // console.log("code")
              // console.log(query.code)
              // console.log(this.getQueryString('code'))
              //this.$store.commit('updateOpenid','123456');
              this.$store.commit('updateCode',query.code?query.code : this.getQueryString('code'));
              setToken(query.code?query.code : this.getQueryString('code'));
              getUser().then((res) => {
                console.info(res.data);

                if(res.data){
                  this.$store.commit('updateOpenid',res.data.openid);
                  this.$store.commit('updatePid',res.data.id);
                  this.$store.commit('updateBaseInfo',res.data);
                  if(res.data.name !=''){
                    this.substatus = true
                  }
                  this.user.id=res.data.id;
                  this.user.name=res.data.name;
                  this.user.birthday=res.data.birthday;
                  // this.user.idCard=response.data.idCard;
                  this.user.telephone=res.data.telephone;
                  this.user.gender=res.data.gender+'';
                  this.user.address=res.data.address;
                  this.user.detail_address=res.data.detail_address;
                  // this.initBaseInfo();

                  this.initData()

                }
              });
              //getBaseInfo({}).then((response) => {
              //   console.info(response);
              // });
            }else{
              removeToken();
            }
          }

        },
    }
</script>

<style scoped>

    .is_completed{
        color: aqua;
    }
    .is_uncompleted{
        color:red;
    }

</style>
